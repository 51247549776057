@font-face {
  font-family: 'MyWebFont';
  src: url('/Atma-Bold.ttf'); /* IE9 Compat Modes */
  src: url('/Atma-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */

}

.detailAlat{
  width: 80%;
  padding-top : 100px;
  padding-bottom: 50px;
}

.card-info{
  width: 100%;
  background:linear-gradient(
                     rgb(28, 12, 0), rgb(55, 21, 0));
  border: none !important;
}

.App {
  font-family: 'MyWebFont', Fallback, sans-serif;
}

.navtrans{
     background: rgba(0,0,0,0.4);
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalTtg{
   background:linear-gradient(
                     rgb(28, 12, 0), rgb(55, 21, 0));
  color : white;
  border-radius: 3px;
}

.App-header{
  background: linear-gradient(
                     rgba(140, 113, 64, 0.9),
                     rgba(140, 113, 64, 0.9)),
                     url("https://ecs7.tokopedia.net/blog-tokopedia-com/uploads/2018/03/mus1.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btn-menu{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width : 200px;
  height: 200px;
  border: none !important;
  background:linear-gradient(
                     rgb(28, 12, 0), rgb(55, 21, 0));

  color: white;
}
.btn-menu:hover{
  color : rgb(255, 255, 157);
  background:linear-gradient(
                      rgb(105, 36, 0), rgb(64, 22, 0));
}

.btn-menu p {
    margin-top: 9px;
    font-size: 1.4em;
}
.App-link {
  color: #61dafb;
}

.angklung-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.angklung:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.alats{
  color : white;
}

.alats:hover{
  text-decoration: none;
  color : peachpuff;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-center {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    transform: scale(1.5);
}

.slick-list {
    padding:80px 50px !important;
    margin-left:10px !important;
    margin-right: 10px !important;
}

.slick-slide img{
    display : inline !important;
    max-width: 150px;
}

.modalLagu{
  font-family: 'MyWebFont', Fallback, sans-serif;
  background:linear-gradient(
                     rgb(28, 12, 0), rgb(55, 21, 0));
  color : white;
  border-radius: 3px;
}